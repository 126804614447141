/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { BackToSignInProps } from "./types";

import TextLink, { TEXT_LINK_SIZES } from "../../../DesignComponents/TextLink";

import AuthContext from "../../../../context/auth/authContext";

const BackToSignIn = ({ isAuthenticatorType }: BackToSignInProps) => {
    const { toggle2FaVerification } = useContext(AuthContext);

    const { t } = useTranslation();

    const codeRequired = t("Auth##account verification##code required");

    const checkAuthType = t(
        `Auth##account verification##check ${isAuthenticatorType ? "autheticator" : "email"}`
    );

    const enterCodeBelow = t("Auth##account verification##enter code below");
    const mainText = `${codeRequired} ${checkAuthType} ${enterCodeBelow}`;

    return (
        <>
            <div
                css={css({
                    marginBottom: "8px"
                })}
            >
                {mainText}

                {!isAuthenticatorType && (
                    <p
                        css={css({
                            margin: "8px 0 0 0"
                        })}
                    >
                        {t("Auth##account verification##code valid")}
                    </p>
                )}
            </div>

            <TextLink
                size={TEXT_LINK_SIZES.Large}
                onClick={() => toggle2FaVerification(false)}
            >
                {t("Auth##back to sign in screen")}
            </TextLink>
        </>
    );
};

export default BackToSignIn;

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import RadioGroup from "@mui/material/RadioGroup";

import { SelectAuthenticationMethodProps } from "../types";

import Radio from "../../../../../../../DesignComponents/Radio";
import Badge, {
    BADGE_COLORS
} from "../../../../../../../DesignComponents/Badge";

import { AUTHENTICATION_TYPES } from "../../../../../../../../shared";

const SelectAuthenticationType = ({
    type,
    changeType
}: SelectAuthenticationMethodProps) => {
    const { t } = useTranslation();

    return (
        <RadioGroup
            value={type}
            onChange={changeType}
            sx={{
                rowGap: "16px"
            }}
        >
            <Radio
                value={AUTHENTICATION_TYPES.Authenticator}
                label={
                    <div
                        css={css({
                            "& > span": {
                                display: "inline-block",
                                marginRight: "10px"
                            },

                            "& > div": {
                                position: "relative",
                                top: "-2px"
                            }
                        })}
                    >
                        <span>{t("Password##authentication app")}</span>

                        <Badge color={BADGE_COLORS.Info}>
                            {t("Password##recommended")}
                        </Badge>
                    </div>
                }
                info={t("Password##authentication app description")}
            />

            <Radio
                value={AUTHENTICATION_TYPES.Email}
                label={t("Password##email")}
                info={t("Password##email description")}
            />
        </RadioGroup>
    );
};

export { SelectAuthenticationType };

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import EnableTwoFactorAuthentication from "../../Navbar/Controls/Settings/Security/TwoFactorAuthentication/EnableTwoFactorAuthentication";

import DialogNewFeature from "../../DesignComponents/DialogNewFeature";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../DesignComponents/Button";

import AuthContext from "../../../context/auth/authContext";
import { ReactComponent as TwoFactorDisabled } from "../../../assets/infoPopUps/TwoFactorDisabled.svg";

const TwoFactorSuggestion = () => {
    const { is2FaSuggestionOpen, resetAuthType, toggle2FaSuggestion } =
        useContext(AuthContext);

    const { t } = useTranslation();

    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        resetAuthType();
    }, [resetAuthType]);

    const openDialog = () => setOpen(true);
    const closeDialog = () => setOpen(false);

    const handleSubmit = () => {
        toggle2FaSuggestion(false);
        openDialog();
    };

    return (
        <>
            <DialogNewFeature
                hideBackdrop
                open={is2FaSuggestionOpen}
                title={t("Password##two-factor suggestion disabled title")}
                description={t(
                    "Password##two-factor suggestion disabled description"
                )}
                image={<TwoFactorDisabled />}
                submit={handleSubmit}
                actions={
                    <Button
                        fullWidth
                        color={BUTTON_COLORS.Primary}
                        size={BUTTON_SIZES.Normal}
                        variant={BUTTON_VARIANTS.TextOnly}
                        type="submit"
                    >
                        {t("Button##continue")}
                    </Button>
                }
            />

            <EnableTwoFactorAuthentication
                isOpen={isOpen}
                close={closeDialog}
                isRequired
            />
        </>
    );
};

export default TwoFactorSuggestion;

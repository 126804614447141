/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import Grid from "@mui/material/Grid2";

import Contacts from "./Contacts";
import { AuthContainerProps } from "../types";

import Logo from "../../Logo";
import ThemeContext from "../../../context/theme/themeContext";
import { useMediaQueries } from "../../../shared";
import bgImageMobile from "../../../assets/backgrounds/auth-background-mobile.png";
import bgImageDesktop from "../../../assets/backgrounds/auth-background-desktop.png";

const AuthContainer = ({
    formName,
    secondaryText,
    children
}: AuthContainerProps) => {
    const {
        colorsFacelift: { textDark, blue100 }
    } = useContext(ThemeContext);

    const { fromMd, toLg } = useMediaQueries();

    return (
        <>
            <section className="remaining-height">
                <Grid
                    container
                    flexDirection={toLg ? "column" : "row"}
                    css={css({
                        flex: 1
                    })}
                >
                    <Grid
                        container
                        flexDirection="column"
                        size={{ xs0: 12, lg: 5 }}
                        css={css({
                            "@media (max-width:839px)": {
                                flex: "1"
                            }
                        })}
                    >
                        <div
                            css={css({
                                display: "flex",
                                padding: "16px 24px",

                                "@media (max-width:599px)": {
                                    padding: "16px"
                                }
                            })}
                        >
                            <Logo />
                        </div>

                        <div
                            css={css({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flex: "1",

                                "@media (max-width:1439px)": {
                                    padding: "64px 0"
                                },

                                "@media (max-width:839px)": {
                                    padding: "144px 0"
                                },

                                "@media (max-width:599px)": {
                                    padding: "64px 16px"
                                }
                            })}
                        >
                            <div
                                css={css({
                                    width: "62%",

                                    "@media (max-width:839px)": {
                                        width: "68%"
                                    },

                                    "@media (max-width:599px)": {
                                        width: "100%"
                                    }
                                })}
                            >
                                <div
                                    css={css({
                                        color: textDark,
                                        marginBottom: "32px",

                                        h1: {
                                            fontSize: "32px",
                                            lineHeight: "44px",
                                            fontWeight: 700,
                                            letterSpacing: "0.25px",
                                            whiteSpace: "pre-line"
                                        },

                                        h3: {
                                            fontSize: "16px",
                                            lineHeight: "20px",
                                            fontWeight: 600,
                                            letterSpacing: "0.15px",
                                            display: "flex",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            marginTop: "8px"
                                        }
                                    })}
                                >
                                    <h1>{formName}</h1>
                                    <h3>{secondaryText}</h3>
                                </div>

                                <div>{children}</div>

                                <Contacts />
                            </div>
                        </div>
                    </Grid>

                    {fromMd && (
                        <Grid
                            size={{ xs0: 12, lg: 7 }}
                            css={css({
                                backgroundImage: `url(${
                                    toLg ? bgImageMobile : bgImageDesktop
                                })`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: toLg ? "bottom" : "right",
                                backgroundColor: blue100,

                                "@media (max-width: 1439px)": {
                                    flex: "1"
                                }
                            })}
                        />
                    )}
                </Grid>
            </section>
        </>
    );
};

export default AuthContainer;

import { getEnvironment } from "../../shared";

const get2FaSuggestionOpenStatus = (
    isSupportUser: boolean,
    isEnabled: boolean
) => {
    const { isDevelopmentEnv } = getEnvironment();

    return isSupportUser && !isDevelopmentEnv && !isEnabled;
};

const get2FaEnableFlowSeenStatus = (
    isSupportUser: boolean,
    isEnabled: boolean
) => {
    const { isDevelopmentEnv } = getEnvironment();

    return isSupportUser && !isDevelopmentEnv ? isEnabled : true;
};

export { get2FaSuggestionOpenStatus, get2FaEnableFlowSeenStatus };

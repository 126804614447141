/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import EnableTwoFactorAuthentication from "./EnableTwoFactorAuthentication";
import DisableTwoFactorAuthentication from "./DisableTwoFactorAuthentication";

import ContentBox from "../ContentBox";

import ThemeContext from "../../../../../../context/theme/themeContext";
import AuthContext from "../../../../../../context/auth/authContext";
import TwoFactorAuthenticationIcon from "../../../../../../assets/customIcons/security/TwoFactorAuthenticationIcon";

const TwoFactorAuthentication = () => {
    const {
        colorsFacelift: { green700, red800 }
    } = useContext(ThemeContext);

    const { user } = useContext(AuthContext);

    const { t } = useTranslation();

    const [isEnableDialogOpen, setEnableDialogOpen] = useState(false);
    const [isDisableDialogOpen, setDisableDialogOpen] = useState(false);

    const openDialog = () =>
        isEnabled ? setDisableDialogOpen(true) : setEnableDialogOpen(true);

    const closeDialog = () => {
        setEnableDialogOpen(false);
        setDisableDialogOpen(false);
    };

    const isEnabled = user?.two_factor_auth_enabled;

    return (
        <>
            <ContentBox
                title={t("Password##two-factor authentication")}
                icon={<TwoFactorAuthenticationIcon />}
                text={
                    <>
                        {t("Password##two-factor authentication status")}

                        <span
                            css={css({
                                color: isEnabled ? green700 : red800,
                                fontWeight: "700",
                                marginLeft: "6px"
                            })}
                        >
                            {isEnabled
                                ? t("Password##enabled")
                                : t("Password##disabled")}
                        </span>
                    </>
                }
                margin="32px 0 8px 0"
                linkText={
                    isEnabled ? t("Button##disable") : t("Button##enable")
                }
                linkOnClick={openDialog}
                description={t(
                    "Password##two-factor authentication description"
                )}
            />

            <EnableTwoFactorAuthentication
                isOpen={isEnableDialogOpen}
                close={closeDialog}
            />

            <DisableTwoFactorAuthentication
                isOpen={isDisableDialogOpen}
                close={closeDialog}
            />
        </>
    );
};

export default TwoFactorAuthentication;

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

import ThemeContext from "../../../../../../../../context/theme/themeContext";
import AuthContext from "../../../../../../../../context/auth/authContext";

const GetEmailCode = () => {
    const {
        colorsFacelift: { textDark }
    } = useContext(ThemeContext);

    const { user } = useContext(AuthContext);

    const { t } = useTranslation();

    return (
        <div
            css={css({
                "& > p": {
                    color: textDark,
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    margin: "0",

                    "& > span": {
                        fontWeight: "700"
                    }
                },

                "& > p:first-of-type": {
                    wordBreak: "break-word"
                }
            })}
        >
            <p>
                <Trans
                    i18nKey="Password##get email code first part"
                    values={{ email: user?.email }}
                    components={{
                        1: <span />
                    }}
                />
            </p>

            <p>{t("Password##get email code second part")}</p>
        </div>
    );
};

export { GetEmailCode };

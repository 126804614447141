import { useCallback, useContext, useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useCookies } from "react-cookie";

import AuthContext from "./authContext";
import AuthReducer from "./authReducer";
import {
    AuthStateProps,
    CompanyAttributes,
    LoginUserParameters,
    User,
    UserWithCompanyAttributes
} from "./types";
import {
    SET_LOADING,
    REMOVE_LOADING,
    SET_USER,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    SET_COOKIE_CONSENT,
    UPDATE_USER_COMPANY,
    TOGGLE_2FA_VERIFICATION,
    REMOVE_ERROR_MESSAGE,
    TOGGLE_2FA_ENABLE,
    TOGGLE_2FA_SUGGESTION,
    RESET_AUTH_TYPE
} from "./authActions";

import AlertContext from "../alert/alertContext";
import ParamsContext from "../params/paramsContext";

import {
    AUTHENTICATION_TYPES,
    COMPANY_FILTER,
    COOKIE_NAMES,
    ENDPOINTS,
    FILTER_NAMES,
    I18_NEXT_LANG,
    LANGUAGES,
    ROOT_COMPANY,
    getValidLanguage,
    useApi
} from "../../shared";

const AuthState = ({ children }: AuthStateProps) => {
    const { removeAlert } = useContext(AlertContext);

    const { setQueryParams, changeRootCompanyLevel } =
        useContext(ParamsContext);

    const { i18n } = useTranslation();
    const [cookies] = useCookies([COOKIE_NAMES.Analytics]);

    const initialState = {
        user: null,
        allCompanyLevelSettings: null,
        errorMessage: null,
        authType: null,
        is2FaSuggestionOpen: false,
        is2FaEnableFlowSeen: false,
        is2FaVerification: false,
        isAuthenticated: false,
        isSupportUser: false,
        isAdminUser: false,
        isAdminManagerUser: false,
        isSystemUser: false,
        isCarrierUser: false,
        isLoading: false,
        isAuthLoading: true,
        cookieConsent: {
            [COOKIE_NAMES.Analytics]: String(cookies.analytics)
        }
    };

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    const { postData, logout, getData, handleResponse, getCriticalMessage } =
        useApi();

    const clearQueryParams = useCallback(
        (onlyCompanies?: boolean) => {
            const params = onlyCompanies
                ? { [FILTER_NAMES.CompanyId]: [] }
                : {};

            setQueryParams({ params, removeFilterOptions: true });
            !onlyCompanies && sessionStorage.clear();
            localStorage.removeItem(ROOT_COMPANY);
            localStorage.removeItem(COMPANY_FILTER);
        },
        [setQueryParams]
    );

    const getUserCompanyAttributes = (
        userData: User,
        attributesData: CompanyAttributes
    ) => {
        const { company_level, company_type, root_client_company_id } =
            attributesData;

        const userDataCopy: UserWithCompanyAttributes = {
            ...userData,
            company_level,
            company_type,
            root_client_company_id
        };

        return userDataCopy;
    };

    const handleFailedResponse = useCallback(
        (error: any) => {
            const { message: newErrorMessage } = getCriticalMessage(error);

            dispatch({
                type: AUTH_ERROR,
                payload: newErrorMessage
            });
        },
        [getCriticalMessage]
    );

    const handleSuccessResponse = useCallback(
        async (
            user: User,
            actionType: typeof LOGIN_SUCCESS | typeof SET_USER
        ) => {
            i18n.changeLanguage(user.language);
            localStorage.setItem(I18_NEXT_LANG, user.language);

            const endpoints = [
                getData(`${ENDPOINTS.Companies}/${user.company_id}/attributes`),
                getData(ENDPOINTS.CompanyLevels)
            ];

            try {
                const [
                    {
                        data: { level, root_client_company_id, type }
                    },
                    {
                        data: { company_level_settings }
                    }
                ] = await Promise.all(endpoints);

                const userWithCompanyAtrributes = getUserCompanyAttributes(
                    user,
                    {
                        company_level: level,
                        company_type: type,
                        root_client_company_id
                    }
                );

                dispatch({
                    type: actionType,
                    payload: {
                        user: userWithCompanyAtrributes,
                        companyLevelSettings: company_level_settings
                    }
                });
            } catch (error) {
                handleFailedResponse(error);
            }
        },
        [i18n, getData, handleFailedResponse]
    );

    const updateUserCompany = useCallback(
        async (id: number, isRootSameAsUserCompany: boolean) => {
            const endpoints = [
                getData(`${ENDPOINTS.Companies}/${id}`),
                getData(`${ENDPOINTS.Companies}/${id}/attributes`)
            ];

            try {
                const [
                    { data: companyData },
                    {
                        data: { level, type }
                    }
                ] = await Promise.all(endpoints);

                const {
                    id,
                    name,
                    company_id,
                    deleted_at,
                    created_by,
                    updated_by,
                    code,
                    created_at,
                    updated_at
                } = companyData;

                const updatedUser: UserWithCompanyAttributes = {
                    ...(state.user as UserWithCompanyAttributes),
                    company: {
                        id,
                        name,
                        company_id,
                        deleted_at,
                        created_by: created_by?.id || null,
                        updated_by: updated_by?.id || null,
                        code,
                        created_at,
                        updated_at
                    },
                    company_level: level,
                    company_type: type,
                    fullCompany: companyData
                };

                dispatch({
                    type: UPDATE_USER_COMPANY,
                    payload: updatedUser
                });

                isRootSameAsUserCompany && changeRootCompanyLevel(level);
            } catch (error) {
                handleResponse(error);
            }
        },
        [state.user, getData, changeRootCompanyLevel, handleResponse]
    );

    const checkAuth = useCallback(async () => {
        try {
            setLoading();

            const {
                data: { logged_in, user }
            } = await getData(`${ENDPOINTS.Users}/me`);

            if (logged_in) {
                handleSuccessResponse(user, SET_USER);
            } else {
                const languageFromStorage = localStorage.getItem(I18_NEXT_LANG);

                const language = getValidLanguage(
                    languageFromStorage,
                    LANGUAGES.English
                );

                i18n.changeLanguage(language);
                dispatch({ type: AUTH_ERROR });

                clearQueryParams(true);
            }
        } catch (error) {
            handleFailedResponse(error);
        } finally {
            removeLoading();
        }
    }, [
        i18n,
        clearQueryParams,
        getData,
        handleSuccessResponse,
        handleFailedResponse
    ]);

    const toggle2FaSuggestion = (isOpen: boolean) =>
        dispatch({
            type: TOGGLE_2FA_SUGGESTION,
            payload: isOpen
        });

    const toggle2FaVerification = (
        value: boolean,
        authType?: AUTHENTICATION_TYPES
    ) =>
        dispatch({
            type: TOGGLE_2FA_VERIFICATION,
            payload: { value, authType: authType || null }
        });

    const toggle2FaEnable = (value: boolean, authType: AUTHENTICATION_TYPES) =>
        dispatch({ type: TOGGLE_2FA_ENABLE, payload: { value, authType } });

    const resetAuthType = useCallback(
        () => dispatch({ type: RESET_AUTH_TYPE }),
        []
    );

    const loginUser = useCallback(
        async (parameters: LoginUserParameters) => {
            const { payload, is2Fa, badRequestCallback, successCallback } =
                parameters;

            try {
                setLoading();

                const route = `${ENDPOINTS.Login}${is2Fa ? "/tfa" : ""}`;

                const { data } = await postData(route, payload);

                const is2FaRequired: boolean | undefined =
                    data.two_factor_auth_required;

                const userData: User =
                    data.two_factor_auth_required === undefined
                        ? data
                        : data.user;

                if (is2FaRequired) {
                    const authType: AUTHENTICATION_TYPES =
                        data.two_factor_auth_type;

                    toggle2FaVerification(true, authType);
                } else {
                    handleSuccessResponse(userData, LOGIN_SUCCESS);
                }

                successCallback && successCallback();
            } catch (error) {
                const err = error as AxiosError;

                if (is2Fa && err.response?.status === 400) {
                    removeLoading();
                    badRequestCallback && badRequestCallback();
                } else {
                    handleFailedResponse(error);
                }
            }
        },
        [postData, handleSuccessResponse, handleFailedResponse]
    );

    const logoutUser = useCallback(async () => {
        try {
            setLoading();

            await logout();

            dispatch({ type: AUTH_ERROR });
            clearQueryParams();
            removeAlert();
        } catch (error) {
            dispatch({ type: AUTH_ERROR });

            sessionStorage.clear();
            handleResponse(error);
        }
    }, [logout, clearQueryParams, removeAlert, handleResponse]);

    const removeErrorMessage = useCallback(
        () => dispatch({ type: REMOVE_ERROR_MESSAGE }),
        []
    );

    const setLoading = () => dispatch({ type: SET_LOADING });

    const removeLoading = () => dispatch({ type: REMOVE_LOADING });

    const setCookieConsent = (
        cookieName: COOKIE_NAMES,
        cookieValue: boolean
    ) => {
        dispatch({
            type: SET_COOKIE_CONSENT,
            payload: { cookieName, cookieValue }
        });
    };

    const value = useMemo(
        () => ({
            user: state.user,
            allCompanyLevelSettings: state.allCompanyLevelSettings,
            errorMessage: state.errorMessage,
            authType: state.authType,
            is2FaSuggestionOpen: state.is2FaSuggestionOpen,
            is2FaEnableFlowSeen: state.is2FaEnableFlowSeen,
            is2FaVerification: state.is2FaVerification,
            isAuthenticated: state.isAuthenticated,
            isSupportUser: state.isSupportUser,
            isAdminUser: state.isAdminUser,
            isAdminManagerUser: state.isAdminManagerUser,
            isSystemUser: state.isSystemUser,
            isCarrierUser: state.isCarrierUser,
            isLoading: state.isLoading,
            isAuthLoading: state.isAuthLoading,
            cookieConsent: state.cookieConsent,
            toggle2FaSuggestion,
            toggle2FaVerification,
            toggle2FaEnable,
            resetAuthType,
            updateUserCompany,
            checkAuth,
            loginUser,
            logoutUser,
            setCookieConsent,
            removeErrorMessage
        }),
        [
            state.user,
            state.allCompanyLevelSettings,
            state.errorMessage,
            state.authType,
            state.is2FaSuggestionOpen,
            state.is2FaEnableFlowSeen,
            state.is2FaVerification,
            state.isAuthenticated,
            state.isSupportUser,
            state.isAdminUser,
            state.isAdminManagerUser,
            state.isSystemUser,
            state.isCarrierUser,
            state.isLoading,
            state.isAuthLoading,
            state.cookieConsent,
            resetAuthType,
            updateUserCompany,
            checkAuth,
            loginUser,
            logoutUser,
            removeErrorMessage
        ]
    );

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export default AuthState;

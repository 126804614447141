/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ChangeEvent, useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import {
    GetEmailCode,
    GetSecretKey,
    SelectAuthenticationType,
    SendOtpCodeToEnable
} from "./content";
import {
    ENABLE_AUTHENTICATION_STEPS,
    EnableTwoFactorAuthenticationProps
} from "./types";

import { sendCodeToEmail } from "../functions";

import Dialog from "../../../../../../DesignComponents/Dialog";
import Button, {
    BUTTON_COLORS,
    BUTTON_SIZES,
    BUTTON_VARIANTS
} from "../../../../../../DesignComponents/Button";
import { OtpInputField } from "../../../../../../Auth/shared";

import AlertContext, {
    ALERT_STATUS
} from "../../../../../../../context/alert/alertContext";
import AuthContext from "../../../../../../../context/auth/authContext";
import {
    AUTHENTICATION_TYPES,
    ENDPOINTS,
    isAuthenticatorType,
    maxDialogWidth,
    useApi,
    useMediaQueries
} from "../../../../../../../shared";

const EnableTwoFactorAuthentication = ({
    isOpen,
    close,
    isRequired
}: EnableTwoFactorAuthenticationProps) => {
    const { setAlert } = useContext(AlertContext);

    const { user, toggle2FaEnable } = useContext(AuthContext);

    const { t } = useTranslation();
    const { postData, handleResponse } = useApi();
    const { fromMd } = useMediaQueries();

    const [step, setStep] = useState(ENABLE_AUTHENTICATION_STEPS.SelectMethod);
    const [isLoading, setLoading] = useState(false);
    const [authType, setAuthType] = useState<AUTHENTICATION_TYPES | null>(null);
    const [secretKey, setSecretKey] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [error, setError] = useState<string | null>(null);

    const handleClose = () => {
        close();

        if (isSuccessMessageStep) {
            toggle2FaEnable(true, authType!);

            setAlert({
                status: ALERT_STATUS.Success,
                title: t("Password##two-factor authentication enabled##alert")
            });
        }
    };

    const changeAuthenticationType = (e: ChangeEvent<HTMLInputElement>) =>
        setAuthType(e.target.value as AUTHENTICATION_TYPES);

    const changeStep = (newStep: ENABLE_AUTHENTICATION_STEPS) =>
        setStep(newStep);

    const changeOtpCode = (newValue: string) => {
        setError(null);
        setOtpCode(newValue);
    };

    const getSecretKeySubmit = async () => {
        try {
            setLoading(true);

            const {
                data: { authenticator_key }
            } = await postData(ENDPOINTS.TfaGenerateKey);

            setSecretKey(authenticator_key);
            changeStep(ENABLE_AUTHENTICATION_STEPS.SendOtpCode);
        } catch (error) {
            setSecretKey("");
            handleResponse(error);
        }

        setLoading(false);
    };

    const getEmailCodeSubmit = () => {
        const successCallback = () =>
            changeStep(ENABLE_AUTHENTICATION_STEPS.SendEmailCode);

        sendCodeToEmail(setLoading, successCallback, postData, handleResponse);
    };

    const sendOtpCodeSubmit = async () => {
        try {
            setLoading(true);

            await postData(ENDPOINTS.TfaConfirm, {
                otp_code: otpCode,
                two_factor_auth_type: authType
            });

            changeStep(ENABLE_AUTHENTICATION_STEPS.SuccessMessage);
        } catch (error) {
            const err = error as AxiosError;

            if (err.response?.status === 400) {
                setError(t("Password##incorrect code"));
            } else {
                handleResponse(error);
            }
        }

        setLoading(false);
    };

    const steps = {
        [ENABLE_AUTHENTICATION_STEPS.SelectMethod]: {
            hasSeparators: true,
            title: t("Password##select authentication method"),
            description: t(
                "Password##select authentication method description"
            ),
            secondaryBtn: isRequired ? null : t("Button##close"),
            submitBtn: t("Button##next"),
            isSubmitBtnDisabled: authType === null,
            content: (
                <SelectAuthenticationType
                    type={authType}
                    changeType={changeAuthenticationType}
                />
            ),
            onSecondaryBtnClick: handleClose,
            onSubmit: () => {
                const nextStep = isAuthenticatorType(authType)
                    ? ENABLE_AUTHENTICATION_STEPS.GetSecretKey
                    : ENABLE_AUTHENTICATION_STEPS.GetEmailCode;

                changeStep(nextStep);
            }
        },
        [ENABLE_AUTHENTICATION_STEPS.GetSecretKey]: {
            hasSeparators: true,
            title: t("Password##authentication app"),
            description: t("Password##generate key description"),
            secondaryBtn: t("Button##back"),
            submitBtn: t("Button##continue"),
            isSubmitBtnDisabled: false,
            content: <GetSecretKey />,
            onSecondaryBtnClick: () =>
                changeStep(ENABLE_AUTHENTICATION_STEPS.SelectMethod),
            onSubmit: getSecretKeySubmit
        },
        [ENABLE_AUTHENTICATION_STEPS.GetEmailCode]: {
            hasSeparators: true,
            title: t("Password##authentication email"),
            description: "",
            secondaryBtn: t("Button##back"),
            submitBtn: t("Button##send code"),
            isSubmitBtnDisabled: false,
            content: <GetEmailCode />,
            onSecondaryBtnClick: () =>
                changeStep(ENABLE_AUTHENTICATION_STEPS.SelectMethod),
            onSubmit: getEmailCodeSubmit
        },
        [ENABLE_AUTHENTICATION_STEPS.SendOtpCode]: {
            hasSeparators: true,
            title: t("Password##authentication app"),
            description: "",
            secondaryBtn: t("Button##back"),
            submitBtn: t("Button##verify"),
            isSubmitBtnDisabled: otpCode.length < 6 || authType === null,
            content: (
                <SendOtpCodeToEnable
                    secretKey={secretKey}
                    error={error}
                    isLoading={isLoading}
                    changeCode={changeOtpCode}
                />
            ),
            onSecondaryBtnClick: () => {
                changeStep(ENABLE_AUTHENTICATION_STEPS.GetSecretKey);
                setOtpCode("");
                setError(null);
            },
            onSubmit: sendOtpCodeSubmit
        },
        [ENABLE_AUTHENTICATION_STEPS.SendEmailCode]: {
            hasSeparators: true,
            title: t("Password##authentication email"),
            description: (
                <Trans
                    i18nKey="Password##authentication email description"
                    values={{ email: user?.email }}
                    components={{
                        1: (
                            <span
                                css={css({
                                    fontWeight: "700"
                                })}
                            />
                        )
                    }}
                />
            ),
            secondaryBtn: t("Button##back"),
            submitBtn: t("Button##verify"),
            isSubmitBtnDisabled: otpCode.length < 6 || authType === null,
            content: (
                <OtpInputField
                    isLoading={isLoading}
                    error={error}
                    onChange={changeOtpCode}
                    label={t("Password##verification code")}
                    isRequired
                />
            ),
            onSecondaryBtnClick: () => {
                changeStep(ENABLE_AUTHENTICATION_STEPS.GetEmailCode);
                setError(null);
            },
            onSubmit: sendOtpCodeSubmit
        },
        [ENABLE_AUTHENTICATION_STEPS.SuccessMessage]: {
            hasSeparators: false,
            title: t("Password##two-factor authentication enabled##title"),
            description: t(
                "Password##two-factor authentication enabled##description"
            ),
            secondaryBtn: isRequired ? null : t("Button##close"),
            submitBtn: isRequired ? t("Button##open fota web") : null,
            isSubmitBtnDisabled: false,
            content: null,
            onSecondaryBtnClick: handleClose,
            onSubmit: handleClose
        }
    };

    const isSuccessMessageStep =
        step === ENABLE_AUTHENTICATION_STEPS.SuccessMessage;

    const hasSeparators = steps[step].hasSeparators;
    const title = steps[step].title;
    const description = steps[step].description;
    const secondaryBtnName = steps[step].secondaryBtn;
    const submitBtnName = steps[step].submitBtn;
    const isDisabled = steps[step].isSubmitBtnDisabled;
    const content = steps[step].content;
    const onSecondaryBtnClick = steps[step].onSecondaryBtnClick;
    const onSubmit = steps[step].onSubmit;

    return (
        <Dialog
            hideBackdrop={isRequired}
            open={isOpen}
            close={isRequired ? undefined : handleClose}
            isTitleSeparator={hasSeparators}
            isActionsSeparator={hasSeparators}
            extendToMaxWidth
            title={title}
            description={description}
            submit={onSubmit}
            TransitionProps={{
                onExited: () => {
                    setAuthType(null);
                    setStep(ENABLE_AUTHENTICATION_STEPS.SelectMethod);
                    setSecretKey("");
                    setOtpCode("");
                    setError(null);
                }
            }}
            actions={
                <>
                    {secondaryBtnName && (
                        <Button
                            fullWidth
                            color={BUTTON_COLORS.Secondary}
                            size={BUTTON_SIZES.Normal}
                            variant={BUTTON_VARIANTS.TextOnly}
                            onClick={onSecondaryBtnClick}
                        >
                            {secondaryBtnName}
                        </Button>
                    )}

                    {submitBtnName && (
                        <Button
                            key={submitBtnName}
                            fullWidth
                            color={BUTTON_COLORS.Primary}
                            size={BUTTON_SIZES.Normal}
                            variant={BUTTON_VARIANTS.TextOnly}
                            type="submit"
                            disabled={isDisabled}
                            isLoading={isLoading}
                        >
                            {submitBtnName}
                        </Button>
                    )}
                </>
            }
            css={css(fromMd && maxDialogWidth)}
        >
            {content}
        </Dialog>
    );
};

export default EnableTwoFactorAuthentication;
